// Generated by purs version 0.14.9
"use strict";
var $foreign = require("./foreign.js");
var showUnit = {
    show: function (v) {
        return "unit";
    }
};
module.exports = {
    showUnit: showUnit,
    unit: $foreign.unit
};
