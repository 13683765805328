// Generated by purs version 0.14.9
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var wrapInRedSpan = function (text) {
    return "<span style='background-color:red'>" + (text + "</span>");
};
var tooManyResults = "More than one correct answer is not allowed for this question type";
var tooFewResults = "No correct answer is specified";
var getCorrectAnswerVal = function (answers) {
    var filteredAnswers = Data_Array.filter((function () {
        var $5 = Data_Maybe.fromMaybe(false);
        return function ($6) {
            return $5((function (v) {
                return v.iscorrect;
            })($6));
        };
    })())(answers);
    var v = Data_Array.length(filteredAnswers);
    if (v === 1) {
        return new Data_Either.Right((function (v1) {
            return v1.answer;
        })(filteredAnswers[0]));
    };
    return new Data_Either.Left(wrapInRedSpan((function () {
        var $4 = v === 0;
        if ($4) {
            return tooFewResults;
        };
        return tooManyResults;
    })()));
};
module.exports = {
    getCorrectAnswerVal: getCorrectAnswerVal
};
