// Generated by purs version 0.14.9
"use strict";
var $foreign = require("./foreign.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List_NonEmpty = require("../Data.List.NonEmpty/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Foreign = require("../Foreign/index.js");
var Foreign_Index = require("../Foreign.Index/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Record = require("../Record/index.js");
var Record_Builder = require("../Record.Builder/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var writeVariantImpl = function (dict) {
    return dict.writeVariantImpl;
};
var writeImplFields = function (dict) {
    return dict.writeImplFields;
};
var writeImpl = function (dict) {
    return dict.writeImpl;
};
var writeJSON = function (dictWriteForeign) {
    var $82 = writeImpl(dictWriteForeign);
    return function ($83) {
        return $foreign["_unsafeStringify"]($82($83));
    };
};
var writeForeignVariant = function () {
    return function (dictWriteForeignVariant) {
        return {
            writeImpl: function (variant) {
                return writeVariantImpl(dictWriteForeignVariant)(Type_Proxy["Proxy"].value)(variant);
            }
        };
    };
};
var writeForeignString = {
    writeImpl: Foreign.unsafeToForeign
};
var writeForeignObject = function (dictWriteForeign) {
    return {
        writeImpl: (function () {
            var $84 = Foreign_Object.mapWithKey(Data_Function["const"](writeImpl(dictWriteForeign)));
            return function ($85) {
                return Foreign.unsafeToForeign($84($85));
            };
        })()
    };
};
var writeForeignNumber = {
    writeImpl: Foreign.unsafeToForeign
};
var writeForeignNullable = function (dictWriteForeign) {
    return {
        writeImpl: (function () {
            var $86 = Data_Maybe.maybe(Foreign.unsafeToForeign(Data_Nullable.toNullable(Data_Maybe.Nothing.value)))(writeImpl(dictWriteForeign));
            return function ($87) {
                return $86(Data_Nullable.toMaybe($87));
            };
        })()
    };
};
var writeForeignInt = {
    writeImpl: Foreign.unsafeToForeign
};
var writeForeignForeign = {
    writeImpl: Control_Category.identity(Control_Category.categoryFn)
};
var writeForeignChar = {
    writeImpl: Foreign.unsafeToForeign
};
var writeForeignBoolean = {
    writeImpl: Foreign.unsafeToForeign
};
var writeForeignArray = function (dictWriteForeign) {
    return {
        writeImpl: function (xs) {
            return Foreign.unsafeToForeign(Data_Functor.map(Data_Functor.functorArray)(writeImpl(dictWriteForeign))(xs));
        }
    };
};
var writeForeignNEArray = function (dictWriteForeign) {
    return {
        writeImpl: function (a) {
            return writeImpl(writeForeignArray(dictWriteForeign))(Data_Array_NonEmpty.toArray(a));
        }
    };
};
var write = function (dictWriteForeign) {
    return writeImpl(dictWriteForeign);
};
var unsafeStringify = $foreign["_unsafeStringify"];
var $$undefined = $foreign["_undefined"];
var writeForeignMaybe = function (dictWriteForeign) {
    return {
        writeImpl: Data_Maybe.maybe($$undefined)(writeImpl(dictWriteForeign))
    };
};
var recordWriteForeign = function () {
    return function (dictWriteForeignFields) {
        return {
            writeImpl: function (rec) {
                var steps = writeImplFields(dictWriteForeignFields)(Type_Proxy["Proxy"].value)(rec);
                return Foreign.unsafeToForeign(Record_Builder.build(steps)({}));
            }
        };
    };
};
var readVariantNil = {
    readVariantImpl: function (v) {
        return function (v1) {
            return Foreign.fail(Data_Identity.monadIdentity)(new Foreign.ForeignError("Unable to match any variant member."));
        };
    }
};
var readVariantImpl = function (dict) {
    return dict.readVariantImpl;
};
var readString = {
    readImpl: Foreign.readString(Data_Identity.monadIdentity)
};
var readNumber = {
    readImpl: Foreign.readNumber(Data_Identity.monadIdentity)
};
var readInt = {
    readImpl: Foreign.readInt(Data_Identity.monadIdentity)
};
var readImpl = function (dict) {
    return dict.readImpl;
};
var readMaybe = function (dictReadForeign) {
    return {
        readImpl: (function () {
            var readNullOrUndefined = function (v) {
                return function (value) {
                    if (Foreign.isNull(value) || Foreign.isUndefined(value)) {
                        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value);
                    };
                    return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Maybe.Just.create)(v(value));
                };
            };
            return readNullOrUndefined(readImpl(dictReadForeign));
        })()
    };
};
var readNullable = function (dictReadForeign) {
    return {
        readImpl: function (o) {
            var reformat = function (error) {
                if (error instanceof Foreign.TypeMismatch) {
                    return new Foreign.TypeMismatch("Nullable " + error.value0, error.value1);
                };
                return error;
            };
            return Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(reformat))(Control_Bind.bindFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Nullable.toNullable))(Data_Traversable.traverse(Data_Traversable.traversableMaybe)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(readImpl(dictReadForeign))))(Foreign.readNull(Data_Identity.monadIdentity)(o)));
        }
    };
};
var readObject = function (dictReadForeign) {
    return {
        readImpl: (function () {
            var readObject$prime = function (value) {
                if (Foreign.tagOf(value) === "Object") {
                    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Foreign.unsafeFromForeign(value));
                };
                if (Data_Boolean.otherwise) {
                    return Foreign.fail(Data_Identity.monadIdentity)(new Foreign.TypeMismatch("Object", Foreign.tagOf(value)));
                };
                throw new Error("Failed pattern match at Simple.JSON (line 197, column 7 - line 197, column 51): " + [ value.constructor.name ]);
            };
            return Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))((function () {
                var $88 = Data_Traversable.sequence(Foreign_Object.traversableObject)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity));
                var $89 = Foreign_Object.mapWithKey(Data_Function["const"](readImpl(dictReadForeign)));
                return function ($90) {
                    return $88($89($90));
                };
            })())(readObject$prime);
        })()
    };
};
var readForeignVariant = function () {
    return function (dictReadForeignVariant) {
        return {
            readImpl: function (o) {
                return readVariantImpl(dictReadForeignVariant)(Type_Proxy["Proxy"].value)(o);
            }
        };
    };
};
var readForeign = {
    readImpl: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))
};
var readFieldsNil = {
    getFields: function (v) {
        return function (v1) {
            return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Control_Category.identity(Record_Builder.categoryBuilder));
        };
    }
};
var readChar = {
    readImpl: Foreign.readChar(Data_Identity.monadIdentity)
};
var readBoolean = {
    readImpl: Foreign.readBoolean(Data_Identity.monadIdentity)
};
var readArray = function (dictReadForeign) {
    return {
        readImpl: (function () {
            var readAtIdx = function (i) {
                return function (f) {
                    return Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(Foreign.ErrorAtIndex.create(i)))(readImpl(dictReadForeign)(f));
                };
            };
            return Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Data_TraversableWithIndex.traverseWithIndex(Data_TraversableWithIndex.traversableWithIndexArray)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(readAtIdx))(Foreign.readArray(Data_Identity.monadIdentity));
        })()
    };
};
var readForeignNEArray = function (dictReadForeign) {
    return {
        readImpl: function (f) {
            return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(readArray(dictReadForeign))(f))(function (v) {
                return Control_Monad_Except_Trans.except(Data_Identity.applicativeIdentity)(Data_Either.note(Data_List_NonEmpty.singleton(new Foreign.ForeignError("Nonempty array expected, got empty array")))(Data_Array_NonEmpty.fromArray(v)));
            });
        }
    };
};
var read$prime = function (dictReadForeign) {
    return readImpl(dictReadForeign);
};
var read = function (dictReadForeign) {
    var $91 = readImpl(dictReadForeign);
    return function ($92) {
        return Control_Monad_Except.runExcept($91($92));
    };
};
var readAsForeign = function (dictReadForeign) {
    var $93 = read(dictReadForeign);
    return function ($94) {
        return $93(Foreign.unsafeToForeign($94));
    };
};
var read_ = function (dictReadForeign) {
    var $95 = read(dictReadForeign);
    return function ($96) {
        return Data_Either.hush($95($96));
    };
};
var parseJSON = (function () {
    var $97 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)((function () {
        var $100 = Control_Applicative.pure(Data_List_Types.applicativeNonEmptyList);
        return function ($101) {
            return $100(Foreign.ForeignError.create(Effect_Exception.message($101)));
        };
    })());
    var $98 = Effect_Uncurried.runEffectFn1($foreign["_parseJSON"]);
    return function ($99) {
        return Control_Monad_Except_Trans.ExceptT(Data_Identity.Identity($97(Effect_Unsafe.unsafePerformEffect(Effect_Exception["try"]($98($99))))));
    };
})();
var readJSON = function (dictReadForeign) {
    var $102 = Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(dictReadForeign))(parseJSON);
    return function ($103) {
        return Control_Monad_Except.runExcept($102($103));
    };
};
var readJSON_ = function (dictReadForeign) {
    var $104 = readJSON(dictReadForeign);
    return function ($105) {
        return Data_Either.hush($104($105));
    };
};
var readJSON$prime = function (dictReadForeign) {
    return Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(dictReadForeign))(parseJSON);
};
var nilWriteForeignVariant = {
    writeVariantImpl: function (v) {
        return function (v1) {
            return Partial_Unsafe.unsafeCrashWith("Variant was not able to be writen row WriteForeign.");
        };
    }
};
var nilWriteForeignFields = {
    writeImplFields: function (v) {
        return function (v1) {
            return Control_Category.identity(Record_Builder.categoryBuilder);
        };
    }
};
var getFields = function (dict) {
    return dict.getFields;
};
var readRecord = function () {
    return function (dictReadForeignFields) {
        return {
            readImpl: function (o) {
                return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Function.flip(Record_Builder.build)({}))(getFields(dictReadForeignFields)(Type_Proxy["Proxy"].value)(o));
            }
        };
    };
};
var consWriteForeignVariant = function (dictIsSymbol) {
    return function (dictWriteForeign) {
        return function () {
            return function (dictWriteForeignVariant) {
                return {
                    writeVariantImpl: function (v) {
                        return function (variant) {
                            var writeVariant = function (value) {
                                return Foreign.unsafeToForeign({
                                    type: Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value),
                                    value: writeImpl(dictWriteForeign)(value)
                                });
                            };
                            return Data_Variant.on()(dictIsSymbol)(Data_Symbol.SProxy.value)(writeVariant)(writeVariantImpl(dictWriteForeignVariant)(Type_Proxy["Proxy"].value))(variant);
                        };
                    }
                };
            };
        };
    };
};
var consWriteForeignFields = function (dictIsSymbol) {
    return function (dictWriteForeign) {
        return function (dictWriteForeignFields) {
            return function () {
                return function () {
                    return function () {
                        return {
                            writeImplFields: function (v) {
                                return function (rec) {
                                    var rest = writeImplFields(dictWriteForeignFields)(Type_Proxy["Proxy"].value)(rec);
                                    var value = writeImpl(dictWriteForeign)(Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(rec));
                                    var result = Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(value))(rest);
                                    return result;
                                };
                            }
                        };
                    };
                };
            };
        };
    };
};
var applyEither = function (dictSemigroup) {
    return function (v) {
        return function (v1) {
            if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Right) {
                return new Data_Either.Left(v.value0);
            };
            if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(Data_Semigroup.append(dictSemigroup)(v.value0)(v1.value0));
            };
            if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(v1.value0);
            };
            if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Right) {
                return new Data_Either.Right(v.value0(v1.value0));
            };
            throw new Error("Failed pattern match at Simple.JSON (line 241, column 1 - line 241, column 90): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var exceptTApply = function (dictSemigroup) {
    return function (dictApplicative) {
        return function (fun) {
            return function (a) {
                return Control_Apply.apply(dictApplicative.Apply0())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(applyEither(dictSemigroup))(Control_Monad_Except_Trans.runExceptT(fun)))(Control_Monad_Except_Trans.runExceptT(a));
            };
        };
    };
};
var readFieldsCons = function (dictIsSymbol) {
    return function (dictReadForeign) {
        return function (dictReadForeignFields) {
            return function () {
                return function () {
                    return {
                        getFields: function (v) {
                            return function (obj) {
                                var rest = getFields(dictReadForeignFields)(Type_Proxy["Proxy"].value)(obj);
                                var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                                var withExcept$prime = Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(Foreign.ErrorAtProperty.create(name)));
                                var first = Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(withExcept$prime(Control_Bind.bindFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(dictReadForeign))(Foreign_Index.readProp(Data_Identity.monadIdentity)(name)(obj))))(function (value) {
                                    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(value));
                                });
                                return exceptTApply(Data_List_Types.semigroupNonEmptyList)(Data_Identity.applicativeIdentity)(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder))(first))(rest);
                            };
                        }
                    };
                };
            };
        };
    };
};
var readVariantCons = function (dictIsSymbol) {
    return function (dictReadForeign) {
        return function () {
            return function (dictReadForeignVariant) {
                return {
                    readVariantImpl: function (v) {
                        return function (o) {
                            var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                            return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(readRecord()(readFieldsCons({
                                reflectSymbol: function () {
                                    return "type";
                                }
                            })(readString)(readFieldsCons({
                                reflectSymbol: function () {
                                    return "value";
                                }
                            })(readForeign)(readFieldsNil)()())()()))(o))(function (v1) {
                                var $80 = v1.type === name;
                                if ($80) {
                                    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(dictReadForeign)(v1.value))(function (v2) {
                                        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Variant.inj()(dictIsSymbol)(Data_Symbol.SProxy.value)(v2));
                                    });
                                };
                                return Foreign.fail(Data_Identity.monadIdentity)(Foreign.ForeignError.create("Did not match variant tag " + name));
                            }))(readVariantImpl(dictReadForeignVariant)(Type_Proxy["Proxy"].value)(o));
                        };
                    }
                };
            };
        };
    };
};
module.exports = {
    readJSON: readJSON,
    "readJSON'": readJSON$prime,
    readJSON_: readJSON_,
    writeJSON: writeJSON,
    write: write,
    read: read,
    "read'": read$prime,
    read_: read_,
    parseJSON: parseJSON,
    "undefined": $$undefined,
    unsafeStringify: unsafeStringify,
    readImpl: readImpl,
    getFields: getFields,
    readVariantImpl: readVariantImpl,
    writeImpl: writeImpl,
    writeImplFields: writeImplFields,
    writeVariantImpl: writeVariantImpl,
    readForeign: readForeign,
    readChar: readChar,
    readNumber: readNumber,
    readInt: readInt,
    readString: readString,
    readBoolean: readBoolean,
    readArray: readArray,
    readMaybe: readMaybe,
    readNullable: readNullable,
    readObject: readObject,
    readRecord: readRecord,
    readFieldsCons: readFieldsCons,
    readFieldsNil: readFieldsNil,
    readForeignVariant: readForeignVariant,
    readVariantNil: readVariantNil,
    readVariantCons: readVariantCons,
    writeForeignForeign: writeForeignForeign,
    writeForeignString: writeForeignString,
    writeForeignInt: writeForeignInt,
    writeForeignChar: writeForeignChar,
    writeForeignNumber: writeForeignNumber,
    writeForeignBoolean: writeForeignBoolean,
    writeForeignArray: writeForeignArray,
    writeForeignMaybe: writeForeignMaybe,
    writeForeignNullable: writeForeignNullable,
    writeForeignObject: writeForeignObject,
    recordWriteForeign: recordWriteForeign,
    consWriteForeignFields: consWriteForeignFields,
    nilWriteForeignFields: nilWriteForeignFields,
    writeForeignVariant: writeForeignVariant,
    nilWriteForeignVariant: nilWriteForeignVariant,
    consWriteForeignVariant: consWriteForeignVariant,
    readForeignNEArray: readForeignNEArray,
    writeForeignNEArray: writeForeignNEArray
};
