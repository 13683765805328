import React from 'react'
import PropTypes from 'prop-types'
//import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
//import { graphql, Link } from 'gatsby'
import { graphql } from 'gatsby'
//import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
//import Content, { HTMLContent } from '../components/Content'

// doesn't seem to work??? undefined: import { MarkdownPreview } from  'netlify-cms-widget-markdown'
//import {NetlifyCmsWidgetMarkdown} from  'netlify-cms-widget-markdown'
// import {markdownToHtml} from  'netlify-cms-widget-markdown/dist/esm/serializers'
// import {MarkdownControl} from 'netlify-cms-widget-markdown/dist/esm/MarkdownControl'
//import {createEditorComponent} from 'netlify-cms-core/dist/esm/valueObjects/EditorComponent'
//var EditorComponent = require('netlify-cms-core/dist/esm/valueObjects/EditorComponent')

// xxx see <script type="application/json" id="stuff">
//     {
//         "unicorns": "awesome",
//         "abc": [1, 2, 3]
//     }
// </script> 
// from https://stackoverflow.com/questions/9320427/best-practice-for-embedding-arbitrary-json-in-the-dom




//This is generated from src/components/all.sass
//import '../../public/admin/cms.css'


//import {MarkdownPreview} from  'netlify-cms-widget-markdown'
//var _MarkdownPreview = _interopRequireDefault(require("./MarkdownPreview")); 

// import { counter as Counter } from "../output/Counter";
// import { variant as Variant } from "../output/Variant";


//mjd 2020-05-09 this DOES seem to be needed here for correct rendering of this component
import 'katex/dist/katex.min.css'

//import LatexContent from '../components/LatexContent'

import { ItemPostGatsbyRenderAdaptor } from '../cms/preview-templates/ItemPostPreview.js'


//Also see https://www.gatsbyjs.org/packages/gatsby-transformer-remark/?=

export const ItemPostTemplate = ({
  content,
    contentComponent,
    tags,
    title,
    smuid,
    titleLevel,
    helmet,
    rawMarkdownBody,
    fm,
}) => {
  //const PostContent = contentComponent || Content

  //console.log("Content is", content);

  // <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
  //     {title}
  // </h1>

  // let titleTag
  // switch (titleLevel) {
  //   case 2:
  //     // code block
  //     titleTag = <h2>A: {title}</h2>
  //     break
  //   case 3:
  //     // code block
  //     titleTag = (
  //       <h3>
  //             <i>B: {title}</i>
  //       </h3>
  //     )
  //     break
  //   default:
  //     // code block
  //     titleTag = (
  //       <h1>
  //             <u>C: {title}</u>
  //       </h1>
  //     )
  // }

  // if (titleLevel) {
  // 	case titleLevel of
  // 	titleTag = <h1>{title}</h1>
  // } else {
  // 	titleTag= <h1>{title}</h1>
  // }
	    // <Counter label="Count1" />
	    // <Variant label="Variant1" />

  // return (
  //   <section className="section">
  //     {helmet || ''}
  //     <div className="container content">
  //       <div className="columns">
  //         <div className="column is-10 is-offset-1">
  //           {titleTag}
  //         <PostContent content={content} />
  //           {tags && tags.length ? (
  //             <div style={{ marginTop: `4rem` }}>
  //               <h4>Tags here</h4>
  //               <ul className="taglist">
  //                 {tags.map((tag) => (
  //                   <li key={tag + `tag`}>
  //                     <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
  //                   </li>
  //                 ))}
  //               </ul>
  //             </div>
  //           ) : null}	  
  //         </div>
  //       </div>
  //     </div>
  //   </section>
    // )

    let jsItem = {questiontype: fm.questiontype, answersets: fm.answersets, tags: fm.tags}
    
  return (
          <ItemPostGatsbyRenderAdaptor jsItem={jsItem} title={title} smuid={smuid} questionContent={rawMarkdownBody} workedSolutionContent={fm.workedsolution} />
  )
    
  // return (
  //   <section className="section">
  //     {helmet || ''}
  //     <div className="container content">
  //       <div className="columns">
  //         <div className="column is-10 is-offset-1">
  //           {titleTag}
  //         <PostContent content={content} />
  //           {tags && tags.length ? (
  //             <div style={{ marginTop: `4rem` }}>
  //               <h4>Tags here</h4>
  //               <ul className="taglist">
  //                 {tags.map((tag) => (
  //                   <li key={tag + `tag`}>
  //                     <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
  //                   </li>
  //                 ))}
  //               </ul>
  //             </div>
  //           ) : null}	  
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // )

}

ItemPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  smuid: PropTypes.string,
  titleLevel: PropTypes.number,
  //    answersets: PropTypes.arrayOf(PropTypes.object),
  //answers: PropTypes.arrayOf({ answer: {answertext : PropTypes.object, iscorrect: PropTypes.boolean}}),
  //answers: PropTypes.array,
    helmet: PropTypes.object,
    fm: PropTypes.object,
}

// export const plugin1 =
//         {
//         // Internal id of the component
//         id: "sm_img",
//         // Visible label
//         label: "SM Image",
//         // Fields the user need to fill out when adding an instance of the component
//         fields: [{name: 'url', label: 'Image url', widget: 'string'},
//                  {name: 'width', label: 'Image width', widget: 'number', default: 350},
//                  {name: 'indent', label: 'Indent size', widget: "select", options: ["", "indent"], default: "indent"},
//                  {name: 'vpad', label: 'Vertical padding amount', widget: "select", options: ["", "vpad"], default: "vpad"}
//                 ],
//         // Pattern to identify a block as being an instance of this component
//         //pattern: /^sm_img (\S+) (\S+)? (\S+)? (\S+$)?/,
//         pattern: /^.*sm_img (\S+)\s?(\S+)?\s?(\S+)?\s?(\S+$)?$/,
//         // Function to extract data elements from the regexp match
//         fromBlock: function(match) {
//             return {
//                 url: match[1],
//                 width: match[2],
//                 indent: match[3],
//                 vpad: match[4]
//             };
//         },
//         // Function to create a text block from an instance of this component
//         toBlock: function(obj) {
//             const url = obj.url
//             const width = obj.width ? " " + obj.width : ""
//             const indent = obj.indent ? " " + obj.indent : ""
//             const vpad = obj.vpad ? " " + obj.vpad : ""
            
//             return 'sm_img ' + url + width + indent + vpad;
//         },
//         // Preview output for this component. Can either be a string or a React component
//         // (component gives better render performance)
//         toPreview: function(obj) {

//             const width = obj.width ? " " + obj.width : ""
//             const indent = obj.indent ? " " + obj.indent : ""
//             const vpad = obj.vpad ? " " + obj.vpad : ""
            
//             return (
//                 '<img class="'+ indent + ' ' + vpad +'" src="' + obj.url + '" width="' + width + '"/>'
//             );
//         }
//     }

//{id: "sm_img", label: "SM Image", fields: [{name: 'url', label: 'Image url', widget: 'string'}, {name: 'width', label: 'Image width', widget: 'number', default: 350}, {name: 'indent', label: 'Indent size', widget: "select", options: ["", "indent"], default: "indent"}, {name: 'vpad', label: 'Vertical padding amount', widget: "select", options: ["", "vpad"], default: "vpad"}], pattern: /^.*sm_img (\S+)\s?(\S+)?\s?(\S+)?\s?(\S+$)?$/, fromBlock: function(match) {return {url: match[1], width: match[2], indent: match[3], vpad: match[4]};}, toBlock: function(obj) {const url = obj.url const width = obj.width ? " " + obj.width : "" const indent = obj.indent ? " " + obj.indent : "" const vpad = obj.vpad ? " " + obj.vpad : "" return 'sm_img ' + url + width + indent + vpad;}, toPreview: function(obj) {const width = obj.width ? " " + obj.width : "" const indent = obj.indent ? " " + obj.indent : "" const vpad = obj.vpad ? " " + obj.vpad : "" return ('<img class="'+ indent + ' ' + vpad +'" src="' + obj.url + '" width="' + width + '"/>');}}


const ItemPost = ({ data, widgetFor, entry }) => {
    const { markdownRemark: post } = data
    //console.log("post", post )
    // Just wraps the existing content in a react component and waits to render when viewed....BUT want to pre-render, like the body.!... console.log("post", <LatexContent>{post.html}</LatexContent>)
    //console.log("data", data);

    const fm = post.frontmatter

    // console.log("createEditorComponent", EditorComponent.default({}))
    
    //let editorComponent1 = EditorComponent.default(

    // console.log("editorComponent1", editorComponent1)
    
    // let getEditorComponents = () => [ editorComponent1 ]

    //MarkdownControl._getEditorComponents = getEditorComponents

    //console.log("fm:", fm)
    //console.log("NEW markdownToHtml", markdownToHtml(String.raw`${fm.workedsolution}`))



    
    //console.log("NEW markdownToHtml3", markdownToHtml(JSON.stringify(fm.workedsolution)) xxxx)



    
    // console.log("fm.questiontype:", fm.questiontype)
    // console.log("fm.workedsolution", <LatexContent>{fm.workedsolution}</LatexContent>)
    //console.log("fm.answersets", JSON.stringify(fm.answersets))
    // let previewComponent = new NetlifyCmsWidgetMarkdown.previewComponent({value: fm.workedsolution})
    //console.log("NetlifyCmsWidgetMarkdown", widget);
    // console.log("NetlifyCmsWidgetMarkdown", previewComponent.render());
    // console.log("NetlifyCmsWidgetMarkdown", NetlifyCmsWidgetMarkdown)

    //removed 2020-05-14:      answersets={post.frontmatter.answersets}

    // let question = widgetFor('body') || (<p>Empty question content</p>)
    // console.log("question", question);

    
  //console.log("Not run in preview!!!! Only in prod: html is", post.html)

  // return (
  //   <Layout>
  //     <ItemPostTemplate
  //       content={post.html}
  //       contentComponent={HTMLContent}
  //       helmet={
  //         <Helmet titleTemplate="%s | Item">
  //           <title>{`${post.frontmatter.title}`}</title>
  //         </Helmet>
  //       }
  //       title={post.frontmatter.title}
  //     />
  //   </Layout>
    // )

  return (
      <ItemPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Item">
            <title>{`${post.frontmatter.title}`}</title>
          </Helmet>
        }
        tags={post.frontmatter.tags}
      title={post.frontmatter.title}
      smuid={post.frontmatter.smuid}
      rawMarkdownBody={post.rawMarkdownBody}
      fm={fm}
      />
    )

    //let renderedLatex = (<LatexContent>{previewComponent.render()}</LatexContent>)
    //console.log(renderedLatex)
    //    {previewComponent.render()}
    
    // return (
    //         <div class="smcmsdata"
    //     data-v0='{"abc": "def"}'
    //     data-v1='{"def": 456}'>
    //         v0, v1
    //     </div>
    // )
}

ItemPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
    // WILL NOT WORK IN HERE, but is shape of what is passed to preview component from netlify-cms-app
    // entry: PropTypes.shape({
    //     getIn: PropTypes.func,
    // }),
    // widgetFor: PropTypes.func,
}

export default ItemPost

//Removed 2020-05-14         answersets {answers {answer {answertext iscorrect}}}

export const pageQuery = graphql`
  query ItemPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      rawMarkdownBody
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        smuid
        questiontype
        workedsolution
        answersets { 
          categories
          context
          difficulty
          answers {
            answer
            iscorrect
          }
          vars {
            varname
            varval
          }
        }
        tags
      }
    }
  }
`

// export const ItemPostTemplate = ({
//   content,
//   contentComponent,
//     internalnotes,
//     difficulty,
//   tags,
//   title,
//   helmet,
// }) => {
//   const PostContent = contentComponent || Content

//   return (
//     <section className="section">
//       {helmet || ''}
//       <div className="container content">
//         <div className="columns">
//           <div className="column is-10 is-offset-1">
//             <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
//               {title}
//             </h1>
//             <p>{internalnotes}</p>
//             <p>{difficulty}</p>
//             <PostContent content={content} />
//             {tags && tags.length ? (
//               <div style={{ marginTop: `4rem` }}>
//                 <h4>Tags</h4>
//                 <ul className="taglist">
//                   {tags.map(tag => (
//                     <li key={tag + `tag`}>
//                       <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             ) : null}
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

// ItemPostTemplate.propTypes = {
//   content: PropTypes.node.isRequired,
//   contentComponent: PropTypes.func,
//   internalnotes: PropTypes.string,
//   difficulty: PropTypes.number,
//   title: PropTypes.string,
//   helmet: PropTypes.object,
// }

// const ItemPost = ({ data }) => {
//   const { markdownRemark: post } = data

//   return (
//     <Layout>
//       <ItemPostTemplate
//         content={post.html}
//         contentComponent={HTMLContent}
//         internalnotes={post.frontmatter.internalnotes}
//         difficulty={post.frontmatter.difficulty}
//         helmet={
//           <Helmet titleTemplate="%s | Item">
//             <title>{`${post.frontmatter.title}`}</title>
//             <meta
//               name="internalnotes"
//               content={`${post.frontmatter.internalnotes}`}
//             />
//           </Helmet>
//         }
//         tags={post.frontmatter.tags}
//         title={post.frontmatter.title}
//       />
//     </Layout>
//   )
// }

// ItemPost.propTypes = {
//   data: PropTypes.shape({
//     markdownRemark: PropTypes.object,
//   }),
// }

// export default ItemPost

// export const pageQuery = graphql`
//   query ItemPostByID($id: String!) {
//     markdownRemark(id: { eq: $id }) {
//       id
//       html
//       frontmatter {
//         date(formatString: "MMMM DD, YYYY")
//         title
//         internalnotes
//         difficulty
//         tags
//       }
//     }
//   }
// `
