// Generated by purs version 0.14.9
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_List_NonEmpty = require("../Data.List.NonEmpty/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Foreign = require("../Foreign/index.js");
var Model_Item = require("../Model.Item/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var parseAnswers = function (obj) {
    var v = Simple_JSON.read(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons({
        reflectSymbol: function () {
            return "answer";
        }
    })(Simple_JSON.readString)(Simple_JSON.readFieldsCons({
        reflectSymbol: function () {
            return "iscorrect";
        }
    })(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsNil)()())()())))(obj);
    if (v instanceof Data_Either.Right) {
        return Control_Applicative.pure(Data_Either.applicativeEither)(v.value0);
    };
    if (v instanceof Data_Either.Left) {
        return new Data_Either.Left("Could not decode the incoming Item: " + Data_Show.show(Foreign.showForeignError)(Data_List_NonEmpty.head(v.value0)));
    };
    throw new Error("Failed pattern match at Model.Item.Interop (line 18, column 3 - line 22, column 69): " + [ v.constructor.name ]);
};
var jsGetCorrectAnswerVal = function (obj) {
    var v = Control_Bind.bindFlipped(Data_Either.bindEither)(Model_Item.getCorrectAnswerVal)(parseAnswers(obj));
    if (v instanceof Data_Either.Right) {
        return Control_Applicative.pure(Effect.applicativeEffect)(v.value0);
    };
    if (v instanceof Data_Either.Left) {
        return Effect_Exception["throw"](v.value0);
    };
    throw new Error("Failed pattern match at Model.Item.Interop (line 26, column 3 - line 30, column 14): " + [ v.constructor.name ]);
};
module.exports = {
    jsGetCorrectAnswerVal: jsGetCorrectAnswerVal
};
