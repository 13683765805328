// Generated by purs version 0.14.9
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Effect = require("../Effect/index.js");
var monadEffectEffect = {
    liftEffect: Control_Category.identity(Control_Category.categoryFn),
    Monad0: function () {
        return Effect.monadEffect;
    }
};
var liftEffect = function (dict) {
    return dict.liftEffect;
};
module.exports = {
    liftEffect: liftEffect,
    monadEffectEffect: monadEffectEffect
};
