// Generated by purs version 0.14.9
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Dual = function (x) {
    return x;
};
var showDual = function (dictShow) {
    return {
        show: function (v) {
            return "(Dual " + (Data_Show.show(dictShow)(v) + ")");
        }
    };
};
var semigroupDual = function (dictSemigroup) {
    return {
        append: function (v) {
            return function (v1) {
                return Data_Semigroup.append(dictSemigroup)(v1)(v);
            };
        }
    };
};
var ordDual = function (dictOrd) {
    return dictOrd;
};
var monoidDual = function (dictMonoid) {
    return {
        mempty: Data_Monoid.mempty(dictMonoid),
        Semigroup0: function () {
            return semigroupDual(dictMonoid.Semigroup0());
        }
    };
};
var functorDual = {
    map: function (f) {
        return function (m) {
            return f(m);
        };
    }
};
var eqDual = function (dictEq) {
    return dictEq;
};
var eq1Dual = {
    eq1: function (dictEq) {
        return Data_Eq.eq(eqDual(dictEq));
    }
};
var ord1Dual = {
    compare1: function (dictOrd) {
        return Data_Ord.compare(ordDual(dictOrd));
    },
    Eq10: function () {
        return eq1Dual;
    }
};
var boundedDual = function (dictBounded) {
    return dictBounded;
};
var applyDual = {
    apply: function (v) {
        return function (v1) {
            return v(v1);
        };
    },
    Functor0: function () {
        return functorDual;
    }
};
var bindDual = {
    bind: function (v) {
        return function (f) {
            return f(v);
        };
    },
    Apply0: function () {
        return applyDual;
    }
};
var applicativeDual = {
    pure: Dual,
    Apply0: function () {
        return applyDual;
    }
};
var monadDual = {
    Applicative0: function () {
        return applicativeDual;
    },
    Bind1: function () {
        return bindDual;
    }
};
module.exports = {
    Dual: Dual,
    eqDual: eqDual,
    eq1Dual: eq1Dual,
    ordDual: ordDual,
    ord1Dual: ord1Dual,
    boundedDual: boundedDual,
    showDual: showDual,
    functorDual: functorDual,
    applyDual: applyDual,
    applicativeDual: applicativeDual,
    bindDual: bindDual,
    monadDual: monadDual,
    semigroupDual: semigroupDual,
    monoidDual: monoidDual
};
