// Generated by purs version 0.14.9
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var $$try = function (action) {
    return $foreign.catchException((function () {
        var $0 = Control_Applicative.pure(Effect.applicativeEffect);
        return function ($1) {
            return $0(Data_Either.Left.create($1));
        };
    })())(Data_Functor.map(Effect.functorEffect)(Data_Either.Right.create)(action));
};
var $$throw = function ($2) {
    return $foreign.throwException($foreign.error($2));
};
var stack = $foreign.stackImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
var showError = {
    show: $foreign.showErrorImpl
};
module.exports = {
    stack: stack,
    "throw": $$throw,
    "try": $$try,
    showError: showError,
    error: $foreign.error,
    message: $foreign.message,
    name: $foreign.name,
    throwException: $foreign.throwException,
    catchException: $foreign.catchException
};
