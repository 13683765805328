// Generated by purs version 0.14.9
"use strict";
var $foreign = require("./foreign.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Record_Unsafe = require("../Record.Unsafe/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var topRecord = function (dict) {
    return dict.topRecord;
};
var top = function (dict) {
    return dict.top;
};
var boundedUnit = {
    top: Data_Unit.unit,
    bottom: Data_Unit.unit,
    Ord0: function () {
        return Data_Ord.ordUnit;
    }
};
var boundedRecordNil = {
    topRecord: function (v) {
        return function (v1) {
            return {};
        };
    },
    bottomRecord: function (v) {
        return function (v1) {
            return {};
        };
    },
    OrdRecord0: function () {
        return Data_Ord.ordRecordNil;
    }
};
var boundedProxy3 = {
    bottom: Type_Proxy.Proxy3.value,
    top: Type_Proxy.Proxy3.value,
    Ord0: function () {
        return Data_Ord.ordProxy3;
    }
};
var boundedProxy2 = {
    bottom: Type_Proxy.Proxy2.value,
    top: Type_Proxy.Proxy2.value,
    Ord0: function () {
        return Data_Ord.ordProxy2;
    }
};
var boundedProxy = {
    bottom: Type_Proxy["Proxy"].value,
    top: Type_Proxy["Proxy"].value,
    Ord0: function () {
        return Data_Ord.ordProxy;
    }
};
var boundedOrdering = {
    top: Data_Ordering.GT.value,
    bottom: Data_Ordering.LT.value,
    Ord0: function () {
        return Data_Ord.ordOrdering;
    }
};
var boundedNumber = {
    top: $foreign.topNumber,
    bottom: $foreign.bottomNumber,
    Ord0: function () {
        return Data_Ord.ordNumber;
    }
};
var boundedInt = {
    top: $foreign.topInt,
    bottom: $foreign.bottomInt,
    Ord0: function () {
        return Data_Ord.ordInt;
    }
};
var boundedChar = {
    top: $foreign.topChar,
    bottom: $foreign.bottomChar,
    Ord0: function () {
        return Data_Ord.ordChar;
    }
};
var boundedBoolean = {
    top: true,
    bottom: false,
    Ord0: function () {
        return Data_Ord.ordBoolean;
    }
};
var bottomRecord = function (dict) {
    return dict.bottomRecord;
};
var boundedRecord = function () {
    return function (dictBoundedRecord) {
        return {
            top: topRecord(dictBoundedRecord)(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value),
            bottom: bottomRecord(dictBoundedRecord)(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value),
            Ord0: function () {
                return Data_Ord.ordRecord()(dictBoundedRecord.OrdRecord0());
            }
        };
    };
};
var bottom = function (dict) {
    return dict.bottom;
};
var boundedRecordCons = function (dictIsSymbol) {
    return function (dictBounded) {
        return function () {
            return function () {
                return function (dictBoundedRecord) {
                    return {
                        topRecord: function (v) {
                            return function (rowProxy) {
                                var tail = topRecord(dictBoundedRecord)(Type_Proxy["Proxy"].value)(rowProxy);
                                var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                                var insert = Record_Unsafe.unsafeSet(key);
                                return insert(top(dictBounded))(tail);
                            };
                        },
                        bottomRecord: function (v) {
                            return function (rowProxy) {
                                var tail = bottomRecord(dictBoundedRecord)(Type_Proxy["Proxy"].value)(rowProxy);
                                var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                                var insert = Record_Unsafe.unsafeSet(key);
                                return insert(bottom(dictBounded))(tail);
                            };
                        },
                        OrdRecord0: function () {
                            return Data_Ord.ordRecordCons(dictBoundedRecord.OrdRecord0())()(dictIsSymbol)(dictBounded.Ord0());
                        }
                    };
                };
            };
        };
    };
};
module.exports = {
    bottom: bottom,
    top: top,
    bottomRecord: bottomRecord,
    topRecord: topRecord,
    boundedBoolean: boundedBoolean,
    boundedInt: boundedInt,
    boundedChar: boundedChar,
    boundedOrdering: boundedOrdering,
    boundedUnit: boundedUnit,
    boundedNumber: boundedNumber,
    boundedProxy: boundedProxy,
    boundedProxy2: boundedProxy2,
    boundedProxy3: boundedProxy3,
    boundedRecordNil: boundedRecordNil,
    boundedRecordCons: boundedRecordCons,
    boundedRecord: boundedRecord,
    EQ: Data_Ord.EQ,
    GT: Data_Ord.GT,
    LT: Data_Ord.LT,
    compare: Data_Ord.compare
};
