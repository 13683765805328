import React from 'react'
import PropTypes from 'prop-types'
//import { ItemPostTemplate } from '../../templates/item-post'
 
//import { kebabCase } from 'lodash'
//import { Link } from 'gatsby'



// import Remark from "remark";
// import toHAST from "mdast-util-to-hast";

import LatexContent from '../../components/LatexContent'
//import InterpolatedContent from '../../components/InterpolatedContent'

import {jsGetCorrectAnswerVal} from '../../../output/Model.Item.Interop'
//import {jsGetCorrectAnswerVal} from '../../../custom_dependency/smcmsutil'
//import {jsGetCorrectAnswerVal} from 'smcmsutil'

//import { variables as Variables } from "../../output/Variables";
import 'katex/dist/katex.min.css'

//Setup sanctury-js as per https://sanctuary.js.org/#section:overview
//const {create, env} = require ('sanctuary');
//const $ = require ('sanctuary-def');
// const S = create ({
//   checkTypes: process.env.NODE_ENV !== 'production',
//   env: env,
// });

//const S = require('sanctuary')
import S from "sanctuary"
import $ from "sanctuary-def"

//import Content, { HTMLContent } from '../../components/Content'
import { HTMLContent } from '../../components/Content'
import {markdownToHtml} from 'smcmsrenderer'

//const yaml = require('js-yaml');
import yaml from 'js-yaml'
//console.log("a", yaml.load("abc: 1\ndef: 2"))

//import MarkdownPreview from '../../../node_modules/netlify-cms-widget-markdown/dist/esm/MarkdownPreview.js'
// import { WidgetPreviewContainer } from 'netlify-cms-ui-default';
// import { markdownToHtml } from './serializers';

// class MarkdownPreview extends React.Component {
//   static propTypes = {
//     getAsset: PropTypes.func.isRequired,
//     resolveWidget: PropTypes.func.isRequired,
//     value: PropTypes.string,
//   };

//   render() {
//     const { value, getAsset, resolveWidget } = this.props;
//     if (value === null) {
//       return null;
//     }

//     const html = markdownToHtml(value, { getAsset, resolveWidget });

//     return <WidgetPreviewContainer dangerouslySetInnerHTML={{ __html: html }} />;
//   }
// }

// export default MarkdownPreview;



import Mustache from 'mustache'

//mjd 2020-09-13 as per 2020-09-13
var CryptoJS = require("crypto-js");

const baseCellStyle = { background: '#efefef', width: '7em'}
const bgGreen = {background: '#cae6ca', width: '7em', 'textAlign': 'center'}
const bgRed = {background: '#e6caca', width: '7em', 'textAlign': 'center'}

const MC_ONE_ANSWER = "MC_ONE_ANSWER"
const MC_MULTIPLE_ANSWER = "MC_MULTIPLE_ANSWER"

//This is recursive if {{.*}} still exists after pass n
const interpolateVars = (content, vars) => {
	//const vars=this.props.vars
        let varSet = {}

	//let varArray = [] 
	      
        //Update varSet
        if (vars) {
            vars.map((el) => (varSet[el.varname] = el.varval))
        }

	//console.log("vars", vars);
	
	//Replace the template placeholders with the variables in the question
        //const origValue = this.props.content || '' //this.props.content.props.value || ''
	//console.log("item", this.props.content);

	//Replace the template placeholders with the variables in the question
        let interpolatedValue;
	try {
	    interpolatedValue = Mustache.render(content, varSet)
	} catch {
	    interpolatedValue = "Variables are not correctly specified, please check the body, and then the variables section to ensure each variable is specified with a value"
	}

    return (S.test (/{{.*}}/) (interpolatedValue)) ? interpolateVars(interpolatedValue, vars) : interpolatedValue
}

// const interpolateVars = (content, vars) => {
//     //return interpolateVars_(interpolateVars_(content, vars), vars)
//     return interpolateVars_(content, vars)
// }

//clone an object
const clone = S.compose (JSON.parse) (JSON.stringify)

const convertToMarkdown = propName => rec => {
    let markdownElRec = {}
    
    //markdownElRec[propName] = (S.compose (markdownToHtml) (S.compose (S.fromMaybe ("")) (S.get (S.is ($.String)) (propName)))) (rec)
    markdownElRec[propName] = S.pipe([
        S.get (S.is ($.String)) (propName),
        S.maybe ("") (markdownToHtml)
    ]) (rec)
    return {...rec, ...markdownElRec}
}

const makeVariantBlock_CommonJSX = (answerset, answersBlock, errMsg) => {
    const varsBlock = <React.Fragment>
          <tr><td>Variable name</td><td>Variable value</td></tr>
          {answerset.vars ? answerset.vars.map(
              (el, varIdx) => {            
                  let varval = HTMLContent({className: "sm_mode sm-varval", content: el.varval})
                  return <tr key={varIdx + `varkey`}><td style={baseCellStyle}>{el.varname}</td><td><LatexContent>{varval}</LatexContent></td></tr>
              }
          ) : <tr><td>No variables have been specified for this variant</td><td></td></tr>
          }
    </React.Fragment>

    return {
        answersetUpdated: answerset,
        variantBlock: (<React.Fragment>
                       <h3>Variables</h3>
                       <table>
                       <tbody>
                       {varsBlock}
                       </tbody>
                       </table>
                       
                       <h3>Answers</h3>

                       {errMsg}
                       
                       <table>
                       <tbody>
                       {answersBlock}
                       </tbody>
                       </table>
                       </React.Fragment>)
    }
}

// const process_MultipleChoiceOneAnswer = (answerset_) => {    
//     let answerset = clone (answerset_)
    
//     const correctAnswer = S.pipe([
//         S.encase (el => jsGetCorrectAnswerVal(el)()), //(Either Error String)
//         S.either (err => err.message) (S.I), //String
//         el => ({varname: "correctAnswer", varval: el})
//     ]) (answerset.answers)
//     answerset.vars = (answerset.vars) ? S.append (correctAnswer) (answerset.vars) : (S.of (Array) (correctAnswer))

//     //for answers, make array of correct answers.
//     //if <> 1 then create error message
//     // add any correct answer to vars
//     // return answerset and err msg

    
//     //return answerset
//     return {answersetUpdated: answerset, errMsg: ""}
//     // const msg = S.pipe([
//     //     S.encase (el => jsGetCorrectAnswerVal(el)()), //(Either Error String)
//     //     S.either (err => HTMLContent({content: err.message})) (S.K ("")) //String
//     // ]) (answerset.answers)
    
//     // return msg
// }

// const process_MultipleChoiceMultipleAnswer = (answerset_) => {
    
//     let answerset = clone (answerset_)

//     // return S.pipe([
//     //     S.filter (el => el.iscorrect === true),
//     //     S.size,
//     //     S.gte (2),
//     //     S.boolean ("<span style='background-color:red'>For this question type, please specify two or more correct answers</span>") (""),
//     //     msg => HTMLContent({content: msg})
//     // ]) (answerset.answers)


//     //for answers, make array of correct answers.
//     //if <2 then create error message
//     // add all correct answers to vars
//     // return answerset and err msg

//     const correctAnswers = S.pipe([
//         S.filter (el => el.iscorrect === true), // Array Object
//         els => S.zip (S.range (0) (S.size (els))) (els), // Yields: Array (Pair Int Object)
//         S.map (S.pair ((idx) => (el) => {return {varname: "correctAnswer" + idx, varval: el.answer}}))
//     ]) (answerset.answers)
    
//     const errMsg = S.pipe([
//         S.size,
//         S.gte (2),
//         S.boolean ("<span style='background-color:red'>For this question type, please specify two or more correct answers</span>") (""),
//         msg => HTMLContent({content: msg})
//     ]) (correctAnswers)
    
//     answerset.vars = S.concat (answerset.vars) (correctAnswers)
          
//     // const correctAnswer = S.pipe([
//     //     S.encase (el => jsGetCorrectAnswerVal(el)()), //(Either Error String)
//     //     S.either (err => err.message) (S.I), //String
//     //     el => ({varname: "correctAnswer", varval: el})
//     // ]) (answerset.answers)
//     // answerset.vars = (answerset.vars) ? S.append (correctAnswer) (answerset.vars) : (S.of (Array) (correctAnswer))

//     return {answersetUpdated: answerset, errMsg: errMsg}
// }


const process_MultipleChoice = (answerset_, answersValidFn, answersInvalidMsg, isUseIdxAppended) => {
    
    let answerset = clone (answerset_)

    // return S.pipe([
    //     S.filter (el => el.iscorrect === true),
    //     S.size,
    //     S.gte (2),
    //     S.boolean ("<span style='background-color:red'>For this question type, please specify two or more correct answers</span>") (""),
    //     msg => HTMLContent({content: msg})
    // ]) (answerset.answers)


    //for answers, make array of correct answers.
    //if <2 then create error message
    // add all correct answers to vars
    // return answerset and err msg

    const correctAnswers = S.pipe([
        S.filter (el => el.iscorrect === true), // Array Object
        els => S.zip (S.range (0) (S.size (els))) (els), // Yields: Array (Pair Int Object)
        S.map (S.pair ((idx) => (el) => {return {varname: "correctAnswer" + (isUseIdxAppended ? idx : ""), varval: el.answer}}))
    ]) (answerset.answers)
    
    const errMsg = S.pipe([
        S.size,
        //S.gte (2),
        answersValidFn,
        S.boolean (makeErrSpan(answersInvalidMsg)) (""),
        msg => HTMLContent({content: msg})
    ]) (correctAnswers)
    
    answerset.vars = S.concat (answerset.vars) (correctAnswers)
          
    // const correctAnswer = S.pipe([
    //     S.encase (el => jsGetCorrectAnswerVal(el)()), //(Either Error String)
    //     S.either (err => err.message) (S.I), //String
    //     el => ({varname: "correctAnswer", varval: el})
    // ]) (answerset.answers)
    // answerset.vars = (answerset.vars) ? S.append (correctAnswer) (answerset.vars) : (S.of (Array) (correctAnswer))

    return {answersetUpdated: answerset, errMsg: errMsg}
}




const makeVariantBlock_MultipleChoice = (multipleChoiceType) => (answerset_) => {
    let answerset = clone (answerset_)

    // answerset.answers = (answerset.answers) ? answerset.answers : []
    // answerset.vars = (answerset.vars) ? (answerset.vars) : []
    
    // const answersMessageBlock = (multipleChoiceType === MC_ONE_ANSWER) ? process_MultipleChoiceOneAnswer(answerset)
    //       : (multipleChoiceType === MC_MULTIPLE_ANSWER) ? process_MultipleChoiceMultipleAnswer(answerset)
    //       : ""
    // const {answersetUpdated2, errMsg2} = (multipleChoiceType === MC_ONE_ANSWER) ? process_MultipleChoiceOneAnswer(answerset)
    //       : (multipleChoiceType === MC_MULTIPLE_ANSWER) ? process_MultipleChoiceMultipleAnswer(answerset)
    //       : answerset

    const {answersetUpdated, errMsg} = (multipleChoiceType === MC_ONE_ANSWER) ? process_MultipleChoice(answerset, (S.equals (1)), "For this question type, please specify a single correct answer (correctAnswer)", false)
          : (multipleChoiceType === MC_MULTIPLE_ANSWER) ? process_MultipleChoice(answerset, (S.gte (2)), "For this question type, please specify two or more correct answers (correctAnswer0, correctAnswer1 [.. correctAnswerN])", true)
          : {answerset: answerset, errMsg: ""}
    
    //ALERT: THIS IS DESTRUCTIVE to answerset
    //NOT NEEDED, SINCE DONE AFTER VAR INTERPOLATION answerset.vars = (answerset.vars) ? S.map (convertToMarkdown ("varval")) (answerset.vars) : answerset.vars

    // Orig
    // answerset.answers.map(ans => {
    //     ans.answer = markdownToHtml(ans.answer)
    //     return ans
    // })
    // Try 2
    // const convertAnswerToMarkdown = ans => S.pipe([
    //     (S.compose (markdownToHtml) (S.prop ("answer"))),
    //     markdownEl => ({...ans, ...markdownEl})
    // ]) (ans)
    
    //ALERT: THIS IS DESTRUCTIVE to answerset
    answersetUpdated.answers = S.map (convertToMarkdown ("answer")) (answersetUpdated.answers)

    const answersBlock = <React.Fragment>
          <tr><td>Is Correct?</td><td>Answer</td></tr>
          {answersetUpdated.answers ? answersetUpdated.answers.map(
              (el, ansIdx) => {
                  let ans = HTMLContent({className: "sm_mode sm-answer", content: el.answer})            
                  let isCorrect = (el.iscorrect ? <td style={bgGreen}>&#10003;</td> : <td style={bgRed}>x</td>)
                  return<tr key={ansIdx + `anskey`}>{isCorrect}<td><LatexContent>{ans}</LatexContent></td></tr>
              }
          ) : <tr><td>No answers have been specified for this variant</td><td></td></tr>
          }
    </React.Fragment>
    
    //return makeVariantBlock_CommonJSX(answerset, answersBlock, answersMessageBlock)
    return makeVariantBlock_CommonJSX(answersetUpdated, answersBlock, errMsg)
}


//makeErrSpan : String -> String
const makeErrSpan = errMsg => "<span style='padding: 0.2em; background-color:red'>" + errMsg + "</span>"

const makeVariantBlock_AnswerBox = (answerset_) => {

    let answerset = clone (answerset_)
    // prefix: abc def $$xyz$$
    // suffix: some more $
    // width: 10
    // correctAnswer: 234.3      ; required

    //isExistsCorrectAnswer :: a -> Boolean
    const isExistsCorrectAnswer = el => (el && el.correctAnswer) ? true : false  //S.isJust (S.get (S.is ($.String)) ('correctAnswer') (el))

    //addWidthIfNotExist :: record possibly with width and an integer value -> original record or record with width 5
    const addWidthIfNotExist = rec => {
        const width = S.pipe([
            S.get (S.is ($.Number)) ('width'),
            S.maybe (5) (S.clamp (3) (10))
        ]) (rec)
        rec.width = width;        
        return rec;
    }

    const convertCorrectAnswerToString = rec => {rec.correctAnswer = "" + rec.correctAnswer; return rec}
    
    //containsCorrectAnswer :: a -> Either ErrString ParsedObject
    //const makeCorrectAnswerEither = S.ifElse (isExistsCorrectAnswer) (S.compose (S.Right) (S.compose (addWidthIfNotExist) (makeFieldsMarkdown))) (S.K (S.Left ("Please specify a 'correctAnswer: value'")))
    //const makeCorrectAnswerEither = S.ifElse (isExistsCorrectAnswer) (S.compose (S.Right) (S.compose (convertCorrectAnswerToString) (addWidthIfNotExist))) (S.K (S.Left ("Please specify a 'correctAnswer: value'")))
    const makeCorrectAnswerEither = S.ifElse (isExistsCorrectAnswer) (S.compose (S.Right) (addWidthIfNotExist)) (S.K (S.Left ("Please specify a 'correctAnswer: value'")))

    //extractMsg :: a -> String
    const extractMsg = el => S.fromMaybe ("Could not successfully decode the answer input") (S.get (S.is ($.String)) ('reason') (el))
    
    //console.log("answerset.answers", answerset.answers)
    
    //set answersUpdated :: Either msg obj to:
    // foreach answerset.answers
    //   yaml parsed elements err.message or parsed object
    //   require correctAnswer else left err msg
    //   returns an either errMsg parsedObj

    //answerEithers :: Array (Either ErrString ParsedObject)
    const answerEithers = S.map (S.pipe([
        S.prop ("answer"), //string
        el => el.replace ("correctAnswer:", "correctAnswer: !!str"),
        S.encase (yaml.load), //(Either ErrorStr Object)
        S.mapLeft (extractMsg), //Either ErrorStr Object
        S.chain (makeCorrectAnswerEither)
    ])) (answerset.answers)
    //console.log("answerEithers", S.map (S.show) (answerEithers))

    //makeFieldsMarkdown :: Record with prefix and suffix -> record with those fields converted to markdown
    const makeFieldsMarkdown = S.compose (convertToMarkdown ("prefix")) (convertToMarkdown ("suffix"))

    // set answersBlock to
    // with answersJson
    //   ensure has correctAnswer else err msg
    //   either rendered errMsg or create rendered answer
    const correctAnswerObjs = S.map (S.either (S.K ({correctAnswer: "please specify",
                                            prefix: makeErrSpan("check answer"),
                                            suffix: makeErrSpan("check answer"),
                                            width: 5})) (makeFieldsMarkdown)) (answerEithers)
    //console.log("correctAnswerObjs", correctAnswerObjs)
    answerset.answers = correctAnswerObjs


    //answerEithersToVars :: Array (Either MsgString ParsedObj) -> Array of objects as per [{varname: correctAnswer0, varval: 123}, {varname: prefix0, varval ""}, {varname: suffix0, varval: "boxes"}, ...]    
    const answerEithersToVars = els => {
        //answerObjToVars :: Int -> {correctAnswer: 1, etc..} -> [{varname: "correctAnswerX", varval: 1}, etc..]
        const answerObjToVars = (idx) => (obj) => {            
            return [{varname: "correctAnswer"+idx, varval: obj.correctAnswer}, {varname: "prefix"+idx, varval: (obj.prefix) ? obj.prefix : ""}, {varname: "suffix"+idx, varval: (obj.suffix) ? obj.suffix : ""}]
        }
        const answerEitherToVars = (idx) => S.either (el => {return [{varname: "correctAnswer"+idx, varval: makeErrSpan(el)}]}) (answerObjToVars(idx))
        //toAnsObjPairs :: Array (Either MsgString ParsedObj) -> Array (Pair Int (Either MsgString ParsedObj))
        const toAnsObjPairs = (S.zip (S.range (0) (S.size (els))))
        return S.foldMap (Array) (S.pair (answerEitherToVars)) (toAnsObjPairs (els))
    }
    //console.log("answerEithersToVars (answerEithers)", answerEithersToVars (answerEithers))
    answerset.vars = S.concat (answerset.vars) (answerEithersToVars (answerEithers))
    //console.log("answerset", answerset);
    
    const makeAnswerBoxElement = (answer, idx) => {
        //THIS FUNCTION WILL BE DUPLICATED IN ELM in it's own way with events on the input element
        return "<div style='align-items: baseline; display: flex'>" + answer.prefix + "<input class='sm_box' style='margin: 0px 7px 0px 7px; text-align: right; width:"+answer.width+"em'></input>" + answer.suffix + "</div>"
    }

    const answersBlock = <React.Fragment>
        <tr><td>correctAnswerN</td><td>correctAnswerValue</td><td>Answer</td></tr>
        {
            answerset.answers ? answerset.answers.map(
                (el, ansIdx) => {
                    let ans = HTMLContent({className: "sm_mode sm-answer", content: makeAnswerBoxElement (el, ansIdx)})
                    //let correctAnswer = HTMLContent({content: el.correctAnswer})            
                    //let isCorrect = (el.iscorrect ? <td style={bgGreen}>&#10003;</td> : <td style={bgRed}>x</td>)
                    return <tr key={ansIdx + `anskey`}>
                        <td>correctAnswer{ansIdx}</td>
                        <td>{el.correctAnswer}</td>
                        <td><LatexContent>{ans}</LatexContent></td>
                        </tr>
                }
            ) : <tr><td>No answers have been specified for this variant</td><td></td></tr>
        }    
    </React.Fragment>

    const answersMsg = <React.Fragment>
        <div style={{ "padding": '1em', "background-color": '#efefef', "font-size": '.8em'}} >
        <p>Specify one or more 'ANSWER' block(s) as exampled below.<br/>Note: correctAnswer is required, the rest are optional. ("correctAnswer" is what the student would need to type in to the box to get the answer correct.)</p>
        <p>For example:<br/>
        correctAnswer: 123.40<br/><br/>
        And optionally, specify the following, but only if you need something different to the defaults:  'width' defaults to 5 if not present, and valid values are 3 to 10; 'prefix' and 'suffix' default to nothing if not present.<br/>
          prefix: $<br/>
          suffix: mm$^2$<br/>
          width: 5
        </p>
        </div>
        </React.Fragment>    
        
    return makeVariantBlock_CommonJSX(answerset, answersBlock, answersMsg)
}


const makeVariant = (questiontype, answerset) => 
      {
          // answerset.answers = (answerset.answers) ? answerset.answers : []
          // answerset.vars = (answerset.vars) ? answerset.vars.map(el => {
          //     el.varname = (el.varname) ? S.trim (el.varname) : ""
          //     return el
          // }) : []
          
          const variantBlockFn =
                (questiontype === "Multiple Choice (One Answer)") ? makeVariantBlock_MultipleChoice(MC_ONE_ANSWER)
                : (questiontype === "Multiple Choice (Multiple Answer)") ? makeVariantBlock_MultipleChoice(MC_MULTIPLE_ANSWER)
                : (questiontype === "Answer Box") ? makeVariantBlock_AnswerBox
                : S.K ({answersetUpdated: answerset, variantBlock: <p>*** This question type is not yet supported ***</p>})

          const {answersetUpdated, variantBlock} = variantBlockFn(answerset)

          //console.log("answersetUpdated", answersetUpdated)
          
          return {answersetUpdated: answersetUpdated,
                  variantSection: (<React.Fragment>
                                   <h3>Question Type</h3>
                                   <p>{questiontype}</p>
                                   {variantBlock}
                                   </React.Fragment>)
                 }
      }

const makeVariantFn = (title, smuid, questionContent, workedSolutionContent, questiontype) => (answerset, i) => {
    //let inner=(<AnswerSet answerset={answerset} content={content} />);
    //let inner = <AnswerSet answerset={answerset} />
    let vTitle = 'Variant ' + (i)
    //let answers=answerset.answers
    //let vars=answerset.vars

    //prepare answerset in case of new/deleted answers block in netlifycms
    answerset.answers = (answerset.answers) ? answerset.answers : []
    answerset.vars = (answerset.vars) ? answerset.vars.map(el => {
        el.varname = (el.varname) ? S.trim (el.varname) : ""
        return el
    }) : []
    
    let {answersetUpdated, variantSection} = makeVariant(questiontype, answerset)
    
    let qHtmlInterp = markdownToHtml(interpolateVars(questionContent, answersetUpdated.vars))
    //console.log("qHtmlInterp", qHtmlInterp)
    let wsHtmlInterp = markdownToHtml(interpolateVars(workedSolutionContent, answersetUpdated.vars))
        
    const jsonData = {
        "iTitle": title,
        "smuid": smuid,
        "vId" : i,
        "categories" : answerset.categories,
        "qContent": qHtmlInterp,
        "wsContent": wsHtmlInterp,
        "qType": questiontype,
        "aSet": answersetUpdated.answers
    }
    //console.log("jsonData", jsonData);
    const encodedJsonData =  CryptoJS.AES.encrypt(JSON.stringify(jsonData), '79245').toString();          
    
    return (<div key={vTitle}>            
            <div className="smqdata" style={{display: "none"}}>{encodedJsonData}</div>
            <hr />	    
            <h2><u>{vTitle}</u></h2>
            <h3>DifficultyLevel</h3>
            <p>{answerset.difficulty}</p>
	    
            <h3>Question</h3>
            {/* LatexContent><InterpolatedContent content={markdownToHtml(questionContent)} vars={answerset.vars} /></LatexContent */}
            <LatexContent>{HTMLContent({className: title + "v" + i, content: qHtmlInterp})}</LatexContent>
            
            <h3>Worked Solution</h3>           
            {/* <LatexContent><InterpolatedContent content={markdownToHtml(interpolateVars(workedSolutionContent, answerset.vars))} vars={answerset.vars} /></LatexContent> */}
            <LatexContent>{HTMLContent({className: title + "v" + i, content: wsHtmlInterp})}</LatexContent>

            {variantSection}
	    
            </div>
           )
}


const ItemPostCommonRenderCore = ({jsItem, title, smuid, questionContent, workedSolutionContent}) => {

    //jsItem has answersets and tags
    const tags = jsItem.tags ? jsItem.tags : []

    let questionComponent = HTMLContent({className: "sm-q", content: markdownToHtml(questionContent)})    
    //console.log("questionContent", questionContent);
    //console.log("markdownToHtml(questionContent)", markdownToHtml(questionContent))
    
    let workedSolutionComponent = HTMLContent({className: "sm-ws", content: markdownToHtml(workedSolutionContent)})
    //console.log("workedSolutionContent", workedSolutionContent)

    const tagElements = (tags && tags.length) ? (
            <div style={{ marginTop: `2rem` }}>
              <h4>Tags</h4>
              <ul className="taglist">
                {tags.map((tag) => (<li key={tag + `tag`}> {tag} </li>))}
              </ul>
            </div>
    ) : <p></p>


    const variants = (jsItem && jsItem.answersets) ? jsItem.answersets.map(
        // {answersets ? answersets.map(
        (answerset, i) => makeVariantFn(title, smuid, questionContent, workedSolutionContent, jsItem.questiontype)(answerset, i)
    ) : <p>No answerset specified</p>
            
    
    return (<div className="container content">            
              <br/>
              <h2>{title}</h2>
            
              <div className="uninterpolated-content">
                <h3>Question</h3>
                {questionComponent}
            
                <h3>Worked Solution</h3>
                {workedSolutionComponent}
              </div>
            
              {variants}

              {tagElements}

              <br/>

            </div>
           )
    
}

export const ItemPostGatsbyRenderAdaptor = ({jsItem, title, smuid, questionContent, workedSolutionContent}) => {

    
    //jsItem has answersets and tags
    // Also need title, question and workedsolution
    
    //let titleEl = HTMLContent({content: title, className: "sm-title"})
    //let titleEl = Content({content: title, className: "sm-title"})
    //return (<div>{titleEl}</div>)

    //let questionComponent = HTMLContent({className: "sm-q", content: questionContent})
    //let workedSolutionComponent = new MarkdownPreview({value: workedSolutionContent})
    //let workedSolutionComponent = HTMLContent({className: "sm-ws", content: workedSolutionContent})
    

    //return (<ItemPostCommonRenderCore jsItem={jsItem} title={title} questionComponent={questionComponent} workedSolutionComponent={workedSolutionComponent} />)
    return (<ItemPostCommonRenderCore jsItem={jsItem} title={title} smuid={smuid} questionContent={questionContent} workedSolutionContent={workedSolutionContent} />)
    
}


const ItemPostPreview = ({ entry, widgetFor }) => {

    // IN HERE will probably pass something like entry.data to jsItem in Item.Interop (smartconstructor which then calls item smart constructor also????!!! after doing js conversions?) and have it create all, with rules/validations/etc.. OR rather, pass entry.data to each call which then calls jsItem to translate and how handle errors?  with a left in sanctuary? or simply an exception in js?    

    // let jsItem = null
    // try {jsItem = entry.get('data').toJS();} catch (e) {console.log(e)}
    //console.log("widgetFor", widgetFor);

    //console.log("At top of ItemPostPreview component")
    
    let jsItem = entry.get('data').toJS();
    //console.log("jsItem", jsItem);
    
    // const tags = jsItem.tags ? jsItem.tags : []
    
    //console.log("jsItem as JS", tags)

    let questionContent = jsItem.body //widgetFor('body') || (<p>Empty question content</p>)
    //console.log("question", question)
    //let questionComponent = widgetFor('body') || (<p>Empty question content</p>)


    // let question2 = HTMLContent({content: "abc", className: "def"})
    // console.log("el", question)
    
    let workedSolutionContent = jsItem.workedsolution //widgetFor('workedsolution') || (<p>Empty solution content</p>)
    //let workedSolutionComponent = widgetFor('workedsolution') || (<p>Empty solution content</p>)
    
    let title = entry.getIn(['data', 'title'])
    let smuid = entry.getIn(['data', 'smuid'])
    //console.log("smuid is", smuid);


    //as per https://michaelwatts.co/blog/2019-08-01-adding-netlify-cms-to-gatsby-part-2/
    // const remarkOptions = {
    //     commonmark: true,
    //     footnotes: true,
    //     gfm: true,
    //     pedantic: true,
    //     tableOfContents: {
    //         heading: null,
    //         maxDepth: 6,
    //     },
    // };
    // let remark = new Remark().data(`settings`, remarkOptions);

    
    //const markdown = widgetFor("body").props.value;
    // const markdownAst = remark.parse(markdown);
    // const htmlAst = toHAST(markdownAst);

    
    // console.log("question, markdown, markdownAst, htmlAst", question, markdown, markdownAst, htmlAst)



    //         <p>abc</p>
    //           <ItemPostTemplate
    //             content={question}
    //             tags={tags}
    //             title={entry.getIn(['data', 'title'])}
    //           />
    //         <p>def</p>



    return (<ItemPostCommonRenderCore jsItem={jsItem} title={title} smuid={smuid} questionContent={questionContent} workedSolutionContent={workedSolutionContent} />)
    
}


ItemPostPreview.propTypes = {
    entry: PropTypes.shape({
	getIn: PropTypes.func,
    }),
    widgetFor: PropTypes.func,
}

export default ItemPostPreview







// ********************************************************************************
// Other fodder: sanctuary-js example
// ********************************************************************************
// getCorrectAnswerVal :: [{iscorrect: true, answer: "223 $$\alpha$$"}, {etc..}] or even [] -> Either String String
// with a Left being "No correct answer is specified" or "More than one correct answer is not allowed for this question type"
// 
// const wrapInRedSpan = text => `<span style='background-color:red'>${text}</span>`
//
// eslint-disable-next-line
// const xgetCorrectAnswerVal = answers => {
//     const tooManyResults = wrapInRedSpan("More than one correct answer is not allowed for this question type")
//     const tooFewResults = wrapInRedSpan("No correct answer is specified")
//     const correctAnswerError1 = wrapInRedSpan("Could not retrieve the correct answer")
//     const correctAnswerError2 = wrapInRedSpan("Could not extract 'answer' property from the answer")
    
//     const correctAnswerVal = S.pipe ([
// 	S.filter (el => el.iscorrect === true), // :: [{iscorrect: true, answer: "234"}] or possibly even []
// 	S.Right, // :: Either [...]
// 	S.chain (el => S.boolean (S.Left (tooManyResults)) (S.Right (el)) (S.lte (1) (S.size (el)))), // handles 2 or more
// 	S.chain (el => S.boolean (S.Left (tooFewResults)) (S.Right (el)) (S.gte (1) (S.size (el)))), // handles 0 so left "string" or right [{iscorrect: true, answer: "234"}]
// 	S.chain (el => (S.maybeToEither (correctAnswerError1) (S.head (el)))), // left string or Right {iscorrect: true, answer: "234"}
// 	S.chain (el => (S.maybeToEither (correctAnswerError2) (S.get (S.is ($.String)) ('answer') (el)))), //left string or Right "234"
// 	S.either (S.I) (S.I) //Extract the string from right or left
//     ]) (answers);
    
//     return correctAnswerVal
    
// }
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************


// const ItemPostPreview = ({ entry, widgetFor }) => {
//     //const tags = entry.getIn(['data', 'tags'])

//     return (
// 	<div>
// 	    <p>abc</p>
// 	    <ItemPostTemplate
// 	content={widgetFor('body')}
// 	title={entry.getIn(['data', 'title'])}
// 	    />
// 	</div>
//     )
// }

// //Note: not passing the following and more to the preview
// //      internalnotes={entry.getIn(['data', 'internalnotes'])}
// //      difficulty={entry.getIn(['data', 'difficulty'])}
// // tags={tags && tags.toJS()}

