import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import 'katex/dist/katex.min.css'

import 'katex/dist/contrib/mhchem.js'
import renderMathInElement from 'katex/dist/contrib/auto-render'

const LatexContent = ({ children }) => {
    //Legacy from when was a Component before 2020-05-19
    //   componentDidMount() {
    // //    this.renderMaths(this.refs.latexContent)
    //   }
    //   componentDidUpdate() {
    //   //  this.renderMaths(this.refs.latexContent)
    //   }


    //     //Other options for katex here: https://github.com/KaTeX/KaTeX/blob/master/docs/options.md
    //     // Katex docs ref: https://katex.org/docs/browser.html
    //     // Katex supported functions: https://katex.org/docs/support_table.html
    //     // Katex remark https://www.gatsbyjs.org/packages/gatsby-remark-katex/?=gatsby-remark
    //     // And for actual rendered output, see https://www.gatsbyjs.org/packages/gatsby-transformer-remark/?= since this code in here is ONLY NECESSARY for the PREVIEW pane rendering

    const latexContent = useRef(null);
    
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
	renderMathInElement(latexContent.current, {
	    delimiters: [
		{ left: '$$$', right: '$$$', display: true },
		{ left: '\\[', right: '\\]', display: true },
		{ left: '$$', right: '$$', display: false },
		{ left: '\\(', right: '\\)', display: false },
	    ],
            macros: {
                "\\smbox": "\\fcolorbox{#1}{white}{\\color{white}\\huge{\\textbf{a}}}"
            }
	})
    });

    return (<div ref={latexContent}>
	    {children}
	    </div>)
}

LatexContent.propTypes = {
    entry: PropTypes.shape({
	getIn: PropTypes.func,
    }),
    widgetFor: PropTypes.func,
}

export default LatexContent

